var scriptRemoteCommand = {
  
  execute: function() {
    this.bindEvents();
  },
  
  bindEvents: function() {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  },
  
  onDeviceReady: function() {
    var isIOSMobileDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if(isIOSMobileDevice && 'RemoteCommand' in window){
      // THis is only use in iOS
      RemoteCommand.enabled("play", true);
      RemoteCommand.enabled("pause", true);
      RemoteCommand.enabled("nextTrack", true);
      RemoteCommand.enabled("previousTrack", true);
      
      RemoteCommand.on('play', function () {
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('play');
       }
     });
      
      RemoteCommand.on('pause', function () {
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('pause');
       }
     });
      RemoteCommand.on('nextTrack', function () {
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('next');
       }
     });
      
      RemoteCommand.on('previousTrack', function () {
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('previous');
       }
     });
      
      RemoteCommand.on('togglePlayPause', function () {
       if(window.onAndroidRemoteControlPressed){
         window.onAndroidRemoteControlPressed('playpause');
       }
     });
    }
  },
  
};

scriptRemoteCommand.execute();
